<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-700">收益账单</span>
      <div class="a-flex-rcc">
        <le-export-btn :page-param="pageParam"></le-export-btn>
      </div>
    </div>
    <el-card class="el-main">
      <le-search-form
        id="leSearch"
        :can-pack-up="false"
        @reset="handlerRest"
        @search="handlerSearch"
      >
        <le-company-under-select
          v-model="pageParam.params.companyIds"
          label="加盟商"
          :multiple="true"
          :collapse="true"
        ></le-company-under-select>
        <le-date-range
          label="账单时间"
          :min-date.sync="pageParam.params.balanceDateStart"
          :max-date.sync="pageParam.params.balanceDateEnd"
        />
        <le-select-local-search
          v-model="pageParam.params.isConfirm"
          label="账单状态"
          :options="billStatus"
        />
      </le-search-form>
      <le-pagview :page-param="pageParam" @setData="setTableData">
        <el-table
          ref="deviceList"
          v-sticky="{ top: 0, parent: '.el-card__body' }"
          :data="tableData"
          :highlight-current-row="true"
          style="width: 100%"
        >
          <el-table-column prop="balanceDateText" label="日期" min-width="120">
            <template slot-scope="{ row }">
              <span class="a-c-blue font-point" @click="getBillsInfo(row)">
                {{ row.balanceDateText ? row.balanceDateText : "-" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="companyName" label="加盟商" min-width="200"></el-table-column>
          <el-table-column prop="amount" label="总收益（元）" min-width="123">
            <template slot-scope="{ row }">
              <span>￥{{ util.numFormat(row.amount) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="myOrderNum" label="自营订单量" min-width="103"></el-table-column>
          <el-table-column prop="myOrderAmount" min-width="200">
            <template slot="header">
              <div class="a-flex-cfsfs">
                <span>自营订单总金额</span>
                <span class="a-fs-12">(现金/红包/赠送金)(元)</span>
              </div>
            </template>
            <template slot-scope="{ row }">
              <span>￥{{ util.numFormat(row.myOrderAmount) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="orderNum-myOrderNum" label="代理订单量" min-width="103">
            <template slot-scope="{ row }">
              <!-- <span>{{ row.orderNum-row.myOrderNum }}</span> -->
              <span>{{ row.agentOrderNum }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" min-width="200">
            <template slot="header">
              <div class="a-flex-cfsfs">
                <span>代理订单总金额</span>
                <span class="a-fs-12">(现金/红包/赠送金)(元)</span>
              </div>
            </template>
            <template slot-scope="{ row }">
              <span>￥{{ util.numFormat(row.orderAmount - row.myOrderAmount) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="自营收益(元)" min-width="123">
            <template slot-scope="{ row }">
              <span>￥{{ util.numFormat(row.myOrderIncome) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="deviceType" label="代理收益(元)" min-width="123">
            <template slot-scope="{ row }">
              <span>￥{{ util.numFormat(row.orderIncome - row.myOrderIncome) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="myStationSize"
            label="自营站点总数量"
            min-width="123"
          ></el-table-column>
          <el-table-column prop="name" label="设备使用率" min-width="103">
            <template slot-scope="{ row }">
              <span>
                {{ row.deviceUsePercent ? (row.deviceUsePercent * 100).toFixed(2) + "%" : "-" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="isConfirm" label="入账状态" min-width="103">
            <template slot-scope="{ row }">
              <span v-if="row.isConfirm == 0">未入账</span>
              <span v-else-if="row.isConfirm == 1">已入账</span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="入账时间" min-width="160">
            <template slot-scope="{ row }">
              <span>{{ row.confirmTimeText ? row.confirmTimeText : "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作" min-width="75" fixed="right">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="详情" placement="top">
                <img
                  src="../../assets/icon/option-detail.png"
                  class="a-wh-16"
                  @click="getBillsInfo(scope.row)"
                />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
import util from "../../../src/utils/util";
export default {
  data() {
    return {
      tableData: [],
      util: util,
      pageParam: {
        url: this.$Config.apiUrl.getBalanceDateList,
        method: "post",
        params: {
          isConfirm: this.$route.query.billsStatus ? this.$route.query.billsStatus : "",
          companyIds: [],
          balanceDateStart: "",
          balanceDateEnd: "",
        },
        freshCtrl: 1,
      },
      billStatus: [
        {
          label: "未入账",
          value: "0",
        },
        {
          label: "已入账",
          value: "1",
        },
      ],
      fileUrl: "", //文件导出url
    };
  },
  mounted() {},
  methods: {
    //获取设备列表
    setTableData(data, fileUrl) {
      this.tableData = data;
      this.fileUrl = fileUrl;
    },
    handlerRest() {
      this.pageParam.params = {
        isConfirm: "",
        companyIds: [],
        balanceDateStart: "",
        balanceDateEnd: "",
      };
      this.handlerSearch();
    },
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    // 查看收益账单详情
    getBillsInfo(datas) {
      this.$router.push({
        path: "/revenueBills/revenueBills-details",
        query: {
          companyId: datas.companyId,
          balanceDate: datas.balanceDateText,
        },
      });
    },
    exportfile() {
      if (!this.fileUrl) {
        this.$message.warning("暂无可导出文件");
        return;
      }
      this.$confirm("是否确认导出文件？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Utils.downloadUrlFile(this.fileUrl, "收益账单列表");
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
